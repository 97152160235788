import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GlobalContactForm } from '@phx-husky/global-contact-form';

import Layout from 'layout/Layout';
import Container from 'layout/Container';
import Title from 'common/Title';
import BreadCrumbs from 'common/BreadCrumbs';
import ContactUsBlock from 'common/ContactUsBlock';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { schemaService } from 'utils/schemaService';

import { IPropsContactUsPage } from './models';

import './ContactUsPage.scss';
import './ShadowContactUsPage.scss';

const ContactUsPage: FC<IPropsContactUsPage> = ({
  data: { pageData },
  pageContext: { breadcrumbs, link },
}) => {
  const { seo, defaultCompositions, media, helpAndSupport, contactForm } = pageData;

  return (
    <Layout
      seo={seo}
      defaultCompositions={defaultCompositions}
      additionalStructuredSchemas={[schemaService.schemaTypes.BreadcrumbList]}
      dynamicStructuredSchemaValues={{ pageUrl: link, breadcrumbs }}
    >
      <div className="contact-us-page__top-part">
        <Container fluid>
          <BreadCrumbs breadcrumbs={breadcrumbs} />
        </Container>
        <div className="contact-us-page__block">
          <Container fluid>
            <Title dataTestAttr="ContactUsPageTitle" Tag="h1" className="contact-us-page__title">
              {pageData.title}
            </Title>
            {pageData.subText ? (
              <DangerouslySetInnerHtml
                className="contact-us-page__sub-text"
                html={pageData.subText}
              />
            ) : null}
          </Container>
        </div>
      </div>
      {helpAndSupport?.[0]?.properties || media?.[0]?.properties ? (
        <Container fluid>
          <div className="contact-us-page__info">
            {helpAndSupport?.[0]?.properties ? (
              <div className="contact-us-page__info-box">
                <Title
                  dataTestAttr="ContactUsPageTitle"
                  Tag="h2"
                  className="contact-us-page__info-title"
                >
                  {helpAndSupport[0].properties.title}
                </Title>
                <ContactUsBlock content={helpAndSupport[0].properties.contactInfo} />
              </div>
            ) : null}
            {media?.[0]?.properties ? (
              <div className="contact-us-page__info-box">
                <Title
                  dataTestAttr="ContactUsPageTitle"
                  Tag="h2"
                  className="contact-us-page__info-title"
                >
                  {media[0].properties.title}
                </Title>
                <ContactUsBlock content={media[0].properties.contactInfo} />
              </div>
            ) : null}
          </div>
        </Container>
      ) : null}
      {contactForm?.[0]?.properties ? (
        <div className="contact-us-page__form">
          <GlobalContactForm
            src={contactForm[0].properties.url}
            id="contact-form"
            title="contact-form"
            className="contact-form"
          />
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($link: String!, $lang: String!) {
    pageData: contactUs(link: { eq: $link }, lang: { eq: $lang }) {
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      title
      subText
      media {
        properties {
          title
          contactInfo {
            properties {
              contactTitle
              contactValue
              imageIcon {
                properties {
                  accordionItemImageAltText
                  accordionItemImage {
                    ...FragmentGatsbyImage
                  }
                }
              }
            }
          }
        }
      }
      helpAndSupport {
        properties {
          title
          contactInfo {
            properties {
              contactTitle
              contactValue
              imageIcon {
                structure
                properties {
                  accordionItemImageAltText
                  accordionItemImage {
                    ...FragmentGatsbyImage
                  }
                }
              }
            }
          }
        }
      }
      contactForm {
        properties {
          url
        }
      }
    }
  }
`;

export default ContactUsPage;
